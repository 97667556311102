// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ParkingIssuesAPI } from 'api/endpoints';
// Interfaces and types
import { IGetParkingIssuesDetailsRes, IGetParkingIssuesRes } from 'types/parkingIssuesTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getParkingIssuesAsync = createAsyncThunk<
  IGetParkingIssuesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('parkingIssues/getParkingIssues', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await ParkingIssuesAPI.getParkingIssues(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getParkingIssueDetailsAsync = createAsyncThunk<
  IGetParkingIssuesDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('parkingIssues/getParkingIssueDetails', async (params: number, { rejectWithValue }) => {
  try {
    return await ParkingIssuesAPI.getParkingIssueDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getParkingIssuesAsync, getParkingIssueDetailsAsync };
