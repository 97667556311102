// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getParkingIssuesAsync, getParkingIssueDetailsAsync } from './parkingIssuesThank';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetParkingIssuesDetailsRes, IGetParkingIssuesRes } from 'types/parkingIssuesTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceParkingIssuesState {
  parkingIssues: IGetParkingIssuesRes | null;
  parkingIssueDetails: IGetParkingIssuesDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceParkingIssuesState = {
  parkingIssues: null,
  parkingIssueDetails: null,
  error: null,
  isLoading: false,
};

const parkingIssuesSlice = createSlice({
  name: 'parkingIssues',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET PARKING_ISSUES ASYNC THUNK
    builder.addCase(getParkingIssuesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getParkingIssuesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.parkingIssues = payload;
    });
    builder.addCase(getParkingIssuesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET PARKING_ISSUES DETAILS ASYNC THUNK
    builder.addCase(getParkingIssueDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getParkingIssueDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.parkingIssueDetails = payload;
    });
    builder.addCase(getParkingIssueDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default parkingIssuesSlice;
