// Packages
import { Link } from 'react-router-dom';
// Components
import {
  GridCol,
  GridRow,
  MainContent,
  Image,
  Paragraph,
  Text,
  Divider,
  Button,
} from 'components/lib';
// Custom Hooks
import { useCompanyPath, useSignOut } from 'customHooks';
// Assets
import notFoundImg from 'assets/images/404.png';
// Redux
import { useAppSelector, STORE_KEYS } from 'redux/store';
// Modules
import storage from 'modules/storage';
// Helpers
import useDefineRedirectRoute from 'helpers/defineRedirectPage';
// I18n
import { t } from 'i18n';
// Styles
import styles from './notFound.module.scss';

const NotFound = () => {
  const { user } = useAppSelector(STORE_KEYS.USER);
  const signOut = useSignOut();

  const { hasScheduleAccess, hasGpsAccess, hasCheckpointsAccess } = storage.getData();

  const { getUpdatedPath } = useCompanyPath();

  const redirectAuthPage = useDefineRedirectRoute({
    hasScheduleAccess,
    hasGpsAccess,
    hasCheckpointsAccess,
  });

  const handleSignOut = async () => {
    signOut();
  };

  let redirectPage = '/';

  if (user?.id) {
    redirectPage = getUpdatedPath(redirectAuthPage);
  }

  return (
    <MainContent>
      <GridRow align="middle" justify="center" className={styles.contentWrap}>
        <GridCol span={10} style={{ textAlign: 'center' }}>
          <Image src={notFoundImg} alt="404" className={styles.content} />

          <Paragraph strong className={styles.title}>
            {t('notFoundPage.title')}
          </Paragraph>

          <Text className={styles.text}>{t('notFoundPage.text')}</Text>

          <Divider />

          <Button type="primary">
            <Link to={redirectPage}>{t('notFoundPage.linkBtn')}</Link>
          </Button>

          <Button type="default" onClick={handleSignOut} className={styles.signOutBtn}>
            {t('mainNav.subNav.signOut')?.toLocaleUpperCase()}
          </Button>
        </GridCol>
      </GridRow>
    </MainContent>
  );
};

export default NotFound;
