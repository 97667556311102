// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getParkingIssuesTypesAsync,
  createParkingIssueTypesAsync,
  getParkingIssueTypeDetailsAsync,
  updateParkingIssueTypeAsync,
  deleteParkingIssueTypeAsync,
} from './parkingIssueTypesThank';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetParkingIssueTypeDetailsRes,
  IGetParkingIssueTypesRes,
} from 'types/parkingIssuesTypesTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceParkingIssueTypesState {
  parkinIssueTypes: IGetParkingIssueTypesRes | null;
  parkinIssueTypeDetails: IGetParkingIssueTypeDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceParkingIssueTypesState = {
  parkinIssueTypes: null,
  parkinIssueTypeDetails: null,
  error: null,
  isLoading: false,
};

const parkingIssueTypeSlice = createSlice({
  name: 'parkingIssueType',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET PARKING_ISSUES_TYPES ASYNC THUNK
    builder.addCase(getParkingIssuesTypesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getParkingIssuesTypesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.parkinIssueTypes = payload;
    });
    builder.addCase(getParkingIssuesTypesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE PARKING_ISSUES_TYPES  ASYNC THUNK
    builder.addCase(createParkingIssueTypesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createParkingIssueTypesAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createParkingIssueTypesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET PARKING_ISSUES_TYPES_DETAILs ASYNC THUNK
    builder.addCase(getParkingIssueTypeDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getParkingIssueTypeDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.parkinIssueTypeDetails = payload;
    });
    builder.addCase(getParkingIssueTypeDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE INCIDENT_TYPE (one) ASYNC THUNK
    builder.addCase(updateParkingIssueTypeAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateParkingIssueTypeAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateParkingIssueTypeAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* DELETE INCIDENT_TYPE ASYNC THUNK
    builder.addCase(deleteParkingIssueTypeAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteParkingIssueTypeAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteParkingIssueTypeAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default parkingIssueTypeSlice;
