// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IResMessage, TQueryParams } from 'types/appTypes';
import {
  IGetParkingIssueTypesRes,
  ICreateParkingIssueTypeBody,
  IUpdateParkingIssueTypeBodyWithParam,
  ICreateParkingIssueTypeRes,
  IGetParkingIssueTypeDetailsRes,
} from 'types/parkingIssuesTypesTypes';

export const getParkingIssueTypes = (params: TQueryParams) => {
  return xhr.get<IGetParkingIssueTypesRes, IGetParkingIssueTypesRes>(
    `${ENDPOINTS.parkingIssueTypes}`,
    {
      params,
    },
  );
};

export const createParkingIssueType = (body: ICreateParkingIssueTypeBody) => {
  return xhr.post<ICreateParkingIssueTypeRes, ICreateParkingIssueTypeRes>(
    `${ENDPOINTS.parkingIssueTypes}`,
    body,
  );
};

export const updateParkingIssueType = ({ id, body }: IUpdateParkingIssueTypeBodyWithParam) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.parkingIssueTypes}/${id}`, body);
};

export const getParkingIssueTypeDetails = (id: number) => {
  return xhr.get<IGetParkingIssueTypeDetailsRes, IGetParkingIssueTypeDetailsRes>(
    `${ENDPOINTS.parkingIssueTypes}/${id}`,
  );
};

export const deleteParkingIssueType = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.parkingIssueTypes}/${id}`);
};
