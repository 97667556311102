// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ScansAPI } from 'api/endpoints';
// Interfaces and types
import { IGetScansRes, IGetScanDetails } from 'types/scans';
import { TQueryParams, IErrorRes } from 'types/appTypes';

const getScansAsync = createAsyncThunk<IGetScansRes, TQueryParams, { rejectValue: IErrorRes }>(
  'scans/getScans',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await ScansAPI.getScans(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getScanDetailsAsync = createAsyncThunk<
  IGetScanDetails,
  number,
  { rejectValue: IErrorRes }
>('incidents/getScansDetails', async (params: number, { rejectWithValue }) => {
  try {
    return await ScansAPI.getScanDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getScansAsync, getScanDetailsAsync };
