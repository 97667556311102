// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IGetParkingIssuesRes, IGetParkingIssuesDetailsRes } from 'types/parkingIssuesTypes';
import { TQueryParams } from 'types/appTypes';

export const getParkingIssues = (params: TQueryParams) => {
  return xhr.get<IGetParkingIssuesRes, IGetParkingIssuesRes>(`${ENDPOINTS.parkingIssues}`, {
    params,
  });
};

export const getParkingIssueDetails = (id: number) => {
  return xhr.get<IGetParkingIssuesDetailsRes, IGetParkingIssuesDetailsRes>(
    `${ENDPOINTS.parkingIssues}/${id}`,
  );
};
