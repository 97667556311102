// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { IncidentsAPI } from 'api/endpoints';
// Interfaces and types
import { IGetIncidentsRes, IGetIncidentDetailsRes } from 'types/incidentTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getIncidentsAsync = createAsyncThunk<
  IGetIncidentsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('incidents/getIncidents', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await IncidentsAPI.getIncidents(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getIncidentDetailsAsync = createAsyncThunk<
  IGetIncidentDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('incidents/getIncidentsDetails', async (params: number, { rejectWithValue }) => {
  try {
    return await IncidentsAPI.getIncidentDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getIncidentsAsync, getIncidentDetailsAsync };
