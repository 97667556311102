// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getIncidentTypesAsync,
  createIncidentTypesAsync,
  getIncidentTypeDetailsAsync,
  updateIncidentTypeAsync,
  deleteIncidentTypeAsync,
  copyIncidentTypeAsync,
} from './incidentTypesThank';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetIncidentTypesRes, IIncidentTypeDetailsRes } from 'types/incidentTypesTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceIncidentTypesState {
  incidentTypes: IGetIncidentTypesRes | null;
  incidentTypeDetails: IIncidentTypeDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceIncidentTypesState = {
  incidentTypes: null,
  incidentTypeDetails: null,
  error: null,
  isLoading: false,
};

const incidentTypeSlice = createSlice({
  name: 'incidentType',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET IDENT_TYPES ASYNC THUNK
    builder.addCase(getIncidentTypesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getIncidentTypesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.incidentTypes = payload;
    });
    builder.addCase(getIncidentTypesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE IDENT_TYPE ASYNC THUNK
    builder.addCase(createIncidentTypesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createIncidentTypesAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createIncidentTypesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET IDENT_TYPE DETAILS ASYNC THUNK
    builder.addCase(getIncidentTypeDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getIncidentTypeDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.incidentTypeDetails = payload;
    });
    builder.addCase(getIncidentTypeDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE INCIDENT_TYPE (one) ASYNC THUNK
    builder.addCase(updateIncidentTypeAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateIncidentTypeAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateIncidentTypeAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* DELETE INCIDENT_TYPE ASYNC THUNK
    builder.addCase(deleteIncidentTypeAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteIncidentTypeAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteIncidentTypeAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* COPY INCIDENT_TYPE ASYNC THUNK
    builder.addCase(copyIncidentTypeAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(copyIncidentTypeAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(copyIncidentTypeAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default incidentTypeSlice;
