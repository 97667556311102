// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { EmployeeAPI } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';
import {
  IGetEmployeesRes,
  ICreateEmployeeRes,
  ICreateEmployeeBody,
  IUpdateEmployeeRes,
  IUpdateEmployeeBody,
  IEmployeeRes,
  IDeleteEmployeeRes,
} from 'types/employeeTypes';

const getEmployeesAsync = createAsyncThunk<
  IGetEmployeesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('employee/getEmployees', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await EmployeeAPI.getEmployees(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createEmployeeAsync = createAsyncThunk<
  ICreateEmployeeRes,
  ICreateEmployeeBody,
  { rejectValue: IErrorRes }
>('employee/createEmployee', async (body: ICreateEmployeeBody, { rejectWithValue }) => {
  try {
    return await EmployeeAPI.createEmployee(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeDetailsAsync = createAsyncThunk<
  IEmployeeRes,
  number,
  { rejectValue: IErrorRes }
>('employee/getEmployeeDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await EmployeeAPI.getEmployeeDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateEmployeeAsync = createAsyncThunk<
  IUpdateEmployeeRes,
  IUpdateEmployeeBody,
  { rejectValue: IErrorRes }
>('employee/updateEmployee', async (cred: IUpdateEmployeeBody, { rejectWithValue }) => {
  try {
    return await EmployeeAPI.updateEmployee(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteEmployeeAsync = createAsyncThunk<
  IDeleteEmployeeRes,
  number,
  { rejectValue: IErrorRes }
>('employee/deleteEmployee', async (id: number, { rejectWithValue }) => {
  try {
    return await EmployeeAPI.deleteEmployee(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const logoutUserByAdminAsync = createAsyncThunk<
  IResMessage,
  number,
  { rejectValue: IErrorRes }
>('employee/logoutUserByAdmin', async (id: number, { rejectWithValue }) => {
  try {
    return await EmployeeAPI.logoutByAdmin(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getEmployeesAsync,
  createEmployeeAsync,
  updateEmployeeAsync,
  getEmployeeDetailsAsync,
  deleteEmployeeAsync,
  logoutUserByAdminAsync,
};
