// Core
import { useState } from 'react';
// Packages
import { useLocation, useHistory, NavLink } from 'react-router-dom';
// Components
import { Table } from 'components';
import { Menu } from 'components/lib';
import { TMenuInfo } from 'components/lib/Menu';
// Routes
import routes from 'router/routes';
import { ROUTES } from 'router/routes';
// Custom Hooks
import { useCompanyPath, useRoutePermissions } from 'customHooks';
// Redux
import { useAppSelector, useAppDispatch, STORE_KEYS } from 'redux/store';
import { toggleModal } from 'redux/nav/navReducer';
// Helpers
import { setQueryString } from 'helpers/queryString';
import {
  getSubMenuSecondLevelItemName,
  getSubMenuThirdLevelItemName,
  ILabelSecondLevelMenu,
  ILabelThirdLevelMenu,
} from '../Navigation/getMenuItemName';
import getAccessToPage from '../Navigation/getAccessToPage';
import { ROLES } from 'helpers/data/constants';
// Types
import { TRoute } from 'router/routes';
import { IPageName } from 'redux/nav/navReducer';
import { TPermissionKey } from 'customHooks/usePermissions';
import { INavHistory } from 'types/userTypes';
// I18n
import { t } from 'i18n';
// Styles
import styles from './navigationMemory.module.scss';

interface IProps {
  isLoadingNav: boolean;
  pages: INavHistory[];
  mode?: 'horizontal' | 'inline' | 'vertical';
}

interface IFirstLevelMenuItemProps {
  path: string;
  defaultQueryStringOnPage: string;
  type?: 'list' | 'default';
}

interface ISecondLevelMenuItemProps extends IFirstLevelMenuItemProps {
  label: string;
  permissionKey: string;
}

interface IModal {
  pageName: IPageName;
  shouldModalBeOpen: boolean;
}

export const getAllowedPagesRoutesWithoutHelpPage = (allowedR: TRoute[]) => {
  return allowedR.filter(({ isNavigate }) => isNavigate);
};

const NavigationMemory = ({ isLoadingNav, pages, mode = 'horizontal' }: IProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const { getUpdatedPath } = useCompanyPath();

  const { user } = useAppSelector(STORE_KEYS.USER);
  const { settings } = useAppSelector(STORE_KEYS.CHECKPOINTS);

  const dispatch = useAppDispatch();

  const [currentKey, setCurrentKey] = useState<string>(pathname);

  const selectedKey = getUpdatedPath(pathname).split('/').slice(0, 3).join('/');

  const allowedRoutes = useRoutePermissions(routes);
  const memoryNavRoutes = allowedRoutes?.filter(routes => {
    return pages.some(page => page.label === routes.label);
  });
  const memoryNavRoutesSorted = memoryNavRoutes.sort((a: TRoute, b: TRoute) => {
    return (
      pages.findIndex(p => p.label === a.label) - pages.findIndex(p => p.label === b.label)
    );
  });

  const { current: page } = Table.paginationSettings;
  const defaultQueryString = setQueryString({ page, limit: user?.paginationPreference });
  const defaultQueryStringWithActiveFilter = setQueryString({
    page,
    limit: user?.paginationPreference,
    status: 'active',
  });

  const handleClick = ({ key }: TMenuInfo) => {
    setCurrentKey(key as string);
  };

  const handleGoToMainMenuPage = ({
    path,
    type,
    defaultQueryStringOnPage,
  }: IFirstLevelMenuItemProps) => {
    history.push(
      `${getUpdatedPath(path)}${type === 'list' ? `?${defaultQueryStringOnPage}` : ''}`,
    );
  };

  const handleOpenCertainPageModal = ({ pageName, shouldModalBeOpen }: IModal) => {
    dispatch(toggleModal({ pageName, shouldModalBeOpen }));
  };

  const getSecondLevelMenuItem = ({
    path,
    defaultQueryStringOnPage,
    type,
    label,
    permissionKey,
  }: ISecondLevelMenuItemProps) => {
    if (!permissionKey || !user) return null;

    const accessToPage = getAccessToPage({
      platformPermissions: user?.platformPermissions,
      neededPermissions: permissionKey as TPermissionKey,
    });

    return getSubMenuSecondLevelItemName(label as any) && accessToPage?.create ? (
      <Menu.Item
        key={`mainMenu${getUpdatedPath(path)}${label}`}
        className={styles.navMenuItem}
        onClick={() =>
          handleOpenCertainPageModal({
            pageName: label as IPageName,
            shouldModalBeOpen: true,
          })
        }
      >
        <NavLink
          to={{
            pathname: `${getUpdatedPath(path)}`,
            state: { fromMenu: true },
            search: type === 'list' ? `?${defaultQueryStringOnPage}` : '?',
          }}
          activeStyle={{ color: 'blue !important' }}
        >
          {getSubMenuSecondLevelItemName(label as ILabelSecondLevelMenu)}
        </NavLink>
      </Menu.Item>
    ) : null;
  };

  //FOR ROLE:BUSINESS_COMPANY we show all menu items/not the most(5) popular
  const menuPages =
    user?.role === ROLES.business_company ? allowedRoutes : memoryNavRoutesSorted;
  const pathWithoutCompanyName = selectedKey.split('/')[2];
  const desktopNav = [...getAllowedPagesRoutesWithoutHelpPage(menuPages)]?.map(
    ({ path, label, type, routes, permissionKey }) => {
      const isActiveFilterShouldBeAdded = label === 'employeesPage';
      const defaultQueryStringOnPage = isActiveFilterShouldBeAdded
        ? defaultQueryStringWithActiveFilter
        : defaultQueryString;

      const isActive = path.includes(pathWithoutCompanyName);
      const navMenuItemClass = isActive ? styles.navMenuItemActive : styles.navMenuItem;

      return (
        <Menu.SubMenu
          key={`mainMenu${getUpdatedPath(path)}${label}${type}`}
          title={t(`mainNav.${label}`)}
          onTitleClick={() => handleGoToMainMenuPage({ path, type, defaultQueryStringOnPage })}
          className={navMenuItemClass}
        >
          {routes.length === 0
            ? getSecondLevelMenuItem({
                defaultQueryStringOnPage,
                path,
                type,
                label,
                permissionKey,
              })
            : routes.map((subRoutes, i) => {
                const isActiveFilterShouldBeAdded =
                  subRoutes.label === 'employeeList' || subRoutes.label === 'invoiceAging';
                const defaultQueryStringOnPage = isActiveFilterShouldBeAdded
                  ? defaultQueryStringWithActiveFilter
                  : defaultQueryString;

                const accessToPage = getAccessToPage({
                  platformPermissions: user?.platformPermissions,
                  neededPermissions: subRoutes.permissionKey as TPermissionKey,
                });

                if (!accessToPage?.read) return null;

                if (
                  subRoutes?.path === ROUTES.CHECKPOINTS_PARKING_LIST &&
                  !settings?.isParkingIssuesEnabled
                ) {
                  return null;
                }

                if (
                  subRoutes?.path === ROUTES.CHECKPOINTS_INCIDENTS_LIST &&
                  !settings?.isIncidentsEnabled
                ) {
                  return null;
                }

                return (
                  <Menu.SubMenu
                    key={`subMenu${getUpdatedPath(subRoutes.path)}${subRoutes.label}`}
                    title={t(`subNav.${subRoutes.label}`)}
                    onTitleClick={() =>
                      handleGoToMainMenuPage({
                        path: subRoutes.path,
                        type: subRoutes.type,
                        defaultQueryStringOnPage: defaultQueryStringOnPage,
                      })
                    }
                    className={styles.navMenuItemSubMenu}
                  >
                    {!getSubMenuThirdLevelItemName(subRoutes.label as ILabelThirdLevelMenu) ||
                    !accessToPage?.create ? null : (
                      <Menu.Item
                        key={`subMenu${getUpdatedPath(subRoutes.path)}${i}`}
                        className={styles.navMenuItem}
                        onClick={() =>
                          handleOpenCertainPageModal({
                            pageName: subRoutes.label as IPageName,
                            shouldModalBeOpen: true,
                          })
                        }
                      >
                        <NavLink
                          to={{
                            pathname: `${getUpdatedPath(subRoutes.path)}`,
                            search:
                              subRoutes.type === 'list' ? `?${defaultQueryStringOnPage}` : '?',
                          }}
                          activeStyle={{ color: 'blue !important' }}
                        >
                          {getSubMenuThirdLevelItemName(
                            subRoutes.label as ILabelThirdLevelMenu,
                          )}
                        </NavLink>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                );
              })}
        </Menu.SubMenu>
      );
    },
  );

  return (
    <>
      {!isLoadingNav && (
        <Menu
          theme="light"
          mode={mode}
          selectedKeys={[selectedKey]}
          defaultSelectedKeys={[currentKey]}
          onClick={handleClick}
          className={styles.navMenu}
        >
          {desktopNav}
        </Menu>
      )}
    </>
  );
};

export default NavigationMemory;
