// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IGetIncidentsRes, IGetIncidentDetailsRes } from 'types/incidentTypes';
import { TQueryParams } from 'types/appTypes';

export const getIncidents = (params: TQueryParams) => {
  return xhr.get<IGetIncidentsRes, IGetIncidentsRes>(`${ENDPOINTS.incidents}`, {
    params,
  });
};

export const getIncidentDetails = (id: number) => {
  return xhr.get<IGetIncidentDetailsRes, IGetIncidentDetailsRes>(
    `${ENDPOINTS.incidents}/${id}`,
  );
};
