// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getCheckpointsAsync,
  updateCheckpointAsync,
  deleteCheckpointAsync,
  getCheckpointsSettingsAsync,
  updateCheckpointsSettingsAsync,
  createCheckpointAsync,
  getCheckpointDetailsAsync,
} from './checkpointsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetCheckpointsRes,
  IGetCheckpointDetailsRes,
  IGetCheckpointsSettingsRes,
} from 'types/checkpointTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceCheckpointState {
  checkpoints: IGetCheckpointsRes | null;
  checkpointDetails: IGetCheckpointDetailsRes | null;
  settings: IGetCheckpointsSettingsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceCheckpointState = {
  checkpoints: null,
  checkpointDetails: null,
  settings: null,
  error: null,
  isLoading: false,
};

const checkpointsSlice = createSlice({
  name: 'checkpoints',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setCheckpointDetails(state, { payload }) {
      state.checkpointDetails = payload || null;
    },
  },
  extraReducers: builder => {
    //* GET CHECKPOINTS ASYNC THUNK
    builder.addCase(getCheckpointsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCheckpointsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.checkpoints = payload;
    });
    builder.addCase(getCheckpointsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE CHECKPOINTS ASYNC THUNK
    builder.addCase(createCheckpointAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createCheckpointAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createCheckpointAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE CHECKPOINT (one) ASYNC THUNK
    builder.addCase(updateCheckpointAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateCheckpointAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateCheckpointAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET CHECKPOINT DETAILS ASYNC THUNK
    builder.addCase(getCheckpointDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCheckpointDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.checkpointDetails = payload;
    });
    builder.addCase(getCheckpointDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE CHECKPOINT ASYNC THUNK
    builder.addCase(deleteCheckpointAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteCheckpointAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteCheckpointAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET CHECKPOINTS SETTINGS ASYNC THUNK
    builder.addCase(getCheckpointsSettingsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCheckpointsSettingsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.settings = payload;
    });
    builder.addCase(getCheckpointsSettingsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE CHECKPOINT (one) ASYNC THUNK
    builder.addCase(updateCheckpointsSettingsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateCheckpointsSettingsAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateCheckpointsSettingsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { setCheckpointDetails } = checkpointsSlice.actions;

export default checkpointsSlice;
