// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ParkingIssuesTypesAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetParkingIssueTypesRes,
  ICreateParkingIssueTypeBody,
  IUpdateParkingIssueTypeBodyWithParam,
  ICreateParkingIssueTypeRes,
  IGetParkingIssueTypeDetailsRes,
} from 'types/parkingIssuesTypesTypes';

import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';

const getParkingIssuesTypesAsync = createAsyncThunk<
  IGetParkingIssueTypesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>(
  'parkingIssuesType/getParkingIssuesTypes',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await ParkingIssuesTypesAPI.getParkingIssueTypes(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createParkingIssueTypesAsync = createAsyncThunk<
  ICreateParkingIssueTypeRes,
  ICreateParkingIssueTypeBody,
  { rejectValue: IErrorRes }
>(
  'parkingIssuesType/createParkingIssueType',
  async (params: ICreateParkingIssueTypeBody, { rejectWithValue }) => {
    try {
      return await ParkingIssuesTypesAPI.createParkingIssueType(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getParkingIssueTypeDetailsAsync = createAsyncThunk<
  IGetParkingIssueTypeDetailsRes,
  number,
  { rejectValue: IErrorRes }
>(
  'parkingIssuesType/getParkingIssueTypeDetails',
  async (params: number, { rejectWithValue }) => {
    try {
      return await ParkingIssuesTypesAPI.getParkingIssueTypeDetails(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateParkingIssueTypeAsync = createAsyncThunk<
  IResMessage,
  IUpdateParkingIssueTypeBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'parkingIssuesType/updateIncidentType',
  async ({ id, body }: IUpdateParkingIssueTypeBodyWithParam, { rejectWithValue }) => {
    try {
      return await ParkingIssuesTypesAPI.updateParkingIssueType({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteParkingIssueTypeAsync = createAsyncThunk<
  IResMessage,
  number,
  { rejectValue: IErrorRes }
>('parkingIssuesType/deleteParkingIssueType', async (id: number, { rejectWithValue }) => {
  try {
    return await ParkingIssuesTypesAPI.deleteParkingIssueType(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getParkingIssuesTypesAsync,
  createParkingIssueTypesAsync,
  getParkingIssueTypeDetailsAsync,
  updateParkingIssueTypeAsync,
  deleteParkingIssueTypeAsync,
};
