// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getScansAsync, getScanDetailsAsync } from './scansThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetScanDetails, IGetScansRes } from 'types/scans';
import { IErrorRes } from 'types/appTypes';

interface ISliceScansState {
  scans: IGetScansRes | null;
  scanDetails: IGetScanDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceScansState = {
  scans: null,
  scanDetails: null,
  error: null,
  isLoading: false,
};

const scansSlice = createSlice({
  name: 'scans',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET SCANS ASYNC THUNK
    builder.addCase(getScansAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getScansAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.scans = payload;
    });
    builder.addCase(getScansAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET SCAN DETAILS ASYNC THUNK
    builder.addCase(getScanDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getScanDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.scanDetails = payload;
    });
    builder.addCase(getScanDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default scansSlice;
