// Core
import { useState } from 'react';
// Packages
import { useLocation, useHistory, Link } from 'react-router-dom';
// Components
import { Table } from 'components';
import { Menu } from 'components/lib';
import { TMenuInfo } from 'components/lib/Menu';
// Routes
import routes, { ROUTES } from 'router/routes';
// Custom Hooks
import { useCompanyPath, useRoutePermissions, useBreakpoint } from 'customHooks';
// Redux
import { useAppSelector, useAppDispatch, STORE_KEYS } from 'redux/store';
import { toggleModal } from 'redux/nav/navReducer';
// Helpers
import { setQueryString } from 'helpers/queryString';
import {
  getSubMenuSecondLevelItemName,
  getSubMenuThirdLevelItemName,
  ILabelSecondLevelMenu,
  ILabelThirdLevelMenu,
} from './getMenuItemName';
import getAccessToPage from './getAccessToPage';
// Types
import { TRoute } from 'router/routes';
import { IPageName } from 'redux/nav/navReducer';
import { TPermissionKey } from 'customHooks/usePermissions';
// I18n
import { t } from 'i18n';
// Styles
import styles from './navigation.module.scss';

interface IProps {
  isMenuVisible?: boolean;
  mode?: 'horizontal' | 'inline' | 'vertical';
  onToggleMenu?: () => void;
}

interface IFirstLevelMenuItemProps {
  path: string;
  defaultQueryStringOnPage: string;
  type?: 'list' | 'default';
}

interface ISecondLevelMenuItemProps extends IFirstLevelMenuItemProps {
  label: string;
  permissionKey: string;
}

interface IModal {
  pageName: IPageName;
  shouldModalBeOpen: boolean;
}

const getEqualPath = (path: string) => {
  return path.split('/').slice(2).join('/');
};

export const getAllowedPagesRoutesWithoutHelpPage = (allowedR: TRoute[]) => {
  return allowedR.filter(({ isNavigate }) => isNavigate);
};

const Navigation = ({ isMenuVisible, mode = 'horizontal', onToggleMenu }: IProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const { lg } = useBreakpoint();

  const { getUpdatedPath } = useCompanyPath();
  const allowedRoutes = useRoutePermissions(routes);

  const { user } = useAppSelector(STORE_KEYS.USER);

  const { settings } = useAppSelector(STORE_KEYS.CHECKPOINTS);
  const dispatch = useAppDispatch();

  const [currentKey, setCurrentKey] = useState<string>(pathname);

  const handleClick = ({ key }: TMenuInfo) => {
    setCurrentKey(key as string);

    onToggleMenu && onToggleMenu();
  };

  const selectedKey = getUpdatedPath(pathname).split('/').slice(0, 3).join('/');

  const { current: page } = Table.paginationSettings;
  const defaultQueryString = setQueryString({ page, limit: user?.paginationPreference });
  const defaultQueryStringWithActiveFilter = setQueryString({
    page,
    limit: user?.paginationPreference,
    status: 'active',
  });

  if (getEqualPath(ROUTES.PAYMENT_CONFIRMED) === getEqualPath(pathname)) {
    return null;
  }

  const handleGoToMainMenuPage = ({
    path,
    type,
    defaultQueryStringOnPage,
  }: IFirstLevelMenuItemProps) => {
    history.push(
      `${getUpdatedPath(path)}${type === 'list' ? `?${defaultQueryStringOnPage}` : ''}`,
    );

    isMenuVisible && onToggleMenu && onToggleMenu();
  };

  const handleGoToHelpPage = () => {
    window.location.replace('https://help.timego.com');
  };

  const handleOpenCertainPageModal = ({ pageName, shouldModalBeOpen }: IModal) => {
    dispatch(toggleModal({ pageName, shouldModalBeOpen }));
  };

  const getSecondLevelMenuItem = ({
    path,
    defaultQueryStringOnPage,
    type,
    label,
    permissionKey,
  }: ISecondLevelMenuItemProps) => {
    if (!permissionKey || !user) return null;

    const accessToPage = getAccessToPage({
      platformPermissions: user?.platformPermissions,
      neededPermissions: permissionKey as TPermissionKey,
    });

    return getSubMenuSecondLevelItemName(label as any) && accessToPage?.create ? (
      <Menu.Item
        key={`mainMenu${getUpdatedPath(path)}${label}`}
        className={styles.navMenuItem}
        onClick={() =>
          handleOpenCertainPageModal({
            pageName: label as IPageName,
            shouldModalBeOpen: true,
          })
        }
      >
        <Link
          to={{
            pathname: `${getUpdatedPath(path)}`,
            state: { fromMenu: true },
            search: type === 'list' ? `?${defaultQueryStringOnPage}` : '?',
          }}
        >
          {getSubMenuSecondLevelItemName(label as ILabelSecondLevelMenu)}
        </Link>
      </Menu.Item>
    ) : null;
  };

  const desktopNav = [...getAllowedPagesRoutesWithoutHelpPage(allowedRoutes)]?.map(
    ({ path, label, type, routes, permissionKey }) => {
      const isActiveFilterShouldBeAdded = label === 'employeesPage';
      const defaultQueryStringOnPage = isActiveFilterShouldBeAdded
        ? defaultQueryStringWithActiveFilter
        : defaultQueryString;

      if (label !== 'helpPage') {
        return (
          <Menu.SubMenu
            key={`mainMenu${getUpdatedPath(path)}${label}${type}`}
            title={t(`mainNav.${label}`)}
            onTitleClick={() =>
              handleGoToMainMenuPage({ path, type, defaultQueryStringOnPage })
            }
            className={styles.navMenuItem}
          >
            {routes.length === 0
              ? getSecondLevelMenuItem({
                  defaultQueryStringOnPage,
                  path,
                  type,
                  label,
                  permissionKey,
                })
              : routes.map((subRoutes, i) => {
                  const isActiveFilterShouldBeAdded =
                    subRoutes.label === 'employeeList' || subRoutes.label === 'invoiceAging';
                  const defaultQueryStringOnPage = isActiveFilterShouldBeAdded
                    ? defaultQueryStringWithActiveFilter
                    : defaultQueryString;

                  const accessToPage = getAccessToPage({
                    platformPermissions: user?.platformPermissions,
                    neededPermissions: subRoutes.permissionKey as TPermissionKey,
                  });

                  if (!accessToPage?.read) return null;

                  if (
                    subRoutes?.path === ROUTES.CHECKPOINTS_PARKING_LIST &&
                    !settings?.isParkingIssuesEnabled
                  ) {
                    return null;
                  }

                  if (
                    subRoutes?.path === ROUTES.CHECKPOINTS_INCIDENTS_LIST &&
                    !settings?.isIncidentsEnabled
                  ) {
                    return null;
                  }

                  return (
                    <Menu.SubMenu
                      key={`subMenu${getUpdatedPath(subRoutes.path)}${subRoutes.label}`}
                      title={t(`subNav.${subRoutes.label}`)}
                      onTitleClick={() =>
                        handleGoToMainMenuPage({
                          path: subRoutes.path,
                          type: subRoutes.type,
                          defaultQueryStringOnPage: defaultQueryStringOnPage,
                        })
                      }
                      className={styles.navMenuItemSubMenu}
                    >
                      {!getSubMenuThirdLevelItemName(
                        subRoutes.label as ILabelThirdLevelMenu,
                      ) || !accessToPage?.create ? null : (
                        <Menu.Item
                          key={`subMenu${getUpdatedPath(subRoutes.path)}${i}`}
                          className={styles.navMenuItem}
                          onClick={() =>
                            handleOpenCertainPageModal({
                              pageName: subRoutes.label as IPageName,
                              shouldModalBeOpen: true,
                            })
                          }
                        >
                          <Link
                            to={{
                              pathname: `${getUpdatedPath(subRoutes.path)}`,
                              search:
                                subRoutes.type === 'list'
                                  ? `?${defaultQueryStringOnPage}`
                                  : '?',
                            }}
                          >
                            {getSubMenuThirdLevelItemName(
                              subRoutes.label as ILabelThirdLevelMenu,
                            )}
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu.SubMenu>
                  );
                })}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.SubMenu
          key={getUpdatedPath(path)}
          title={t(`mainNav.${label}`)}
          onTitleClick={handleGoToHelpPage}
          className={styles.navMenuItem}
        />
      );
    },
  );

  const mobileNav = [...getAllowedPagesRoutesWithoutHelpPage(allowedRoutes)]?.map(
    ({ path, label, type }) => {
      const isActiveFilterShouldBeAdded = label === 'employeesPage';
      const defaultQueryStringOnPage = isActiveFilterShouldBeAdded
        ? defaultQueryStringWithActiveFilter
        : defaultQueryString;

      if (label !== 'helpPage') {
        return (
          <Menu.Item key={getUpdatedPath(path)} className={styles.navMenuItem}>
            <Link
              to={`${getUpdatedPath(path)}${
                type === 'list' ? `?${defaultQueryStringOnPage}` : ''
              }`}
            >
              {t(`mainNav.${label}`)}
            </Link>
          </Menu.Item>
        );
      }

      return (
        <Menu.Item key={getUpdatedPath(path)} className={styles.navMenuItem}>
          <a href="https://help.timego.com"> {t(`mainNav.${label}`)}</a>
        </Menu.Item>
      );
    },
  );

  const nav = lg ? desktopNav : mobileNav;

  return (
    <>
      {isMenuVisible && (
        <Menu
          theme="light"
          mode={mode}
          selectedKeys={[selectedKey]}
          defaultSelectedKeys={[currentKey]}
          onClick={handleClick}
          className={styles.navMenu}
        >
          {nav}
        </Menu>
      )}
    </>
  );
};

export default Navigation;
