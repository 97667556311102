// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IResMessage, TQueryParams } from 'types/appTypes';
import {
  IGetIncidentTypesRes,
  ICreateIncidentTypeBody,
  IUpdateIncidentTypeBodyWithParam,
  ICreateIncidentTypeRes,
  ICopyIncidentTypeBody,
  IIncidentTypeDetailsRes,
} from 'types/incidentTypesTypes';

export const getIncidentsTypes = (params: TQueryParams) => {
  return xhr.get<IGetIncidentTypesRes, IGetIncidentTypesRes>(`${ENDPOINTS.incidentTypes}`, {
    params,
  });
};

export const createIncidentType = (body: ICreateIncidentTypeBody) => {
  return xhr.post<ICreateIncidentTypeRes, ICreateIncidentTypeRes>(
    `${ENDPOINTS.incidentTypes}`,
    body,
  );
};

export const updateIncidentType = ({ id, body }: IUpdateIncidentTypeBodyWithParam) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.incidentTypes}/${id}`, body);
};

export const getIncidentTypeDetails = (id: number) => {
  return xhr.get<IIncidentTypeDetailsRes, IIncidentTypeDetailsRes>(
    `${ENDPOINTS.incidentTypes}/${id}`,
  );
};

export const deleteIncidentType = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.incidentTypes}/${id}`);
};

export const copyIncidentType = (body: ICopyIncidentTypeBody) => {
  return xhr.post<IResMessage, IResMessage>(`${ENDPOINTS.copyIncidentType}`, body);
};
