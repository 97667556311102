// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { CheckpointsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetCheckpointsRes,
  IUpdateCheckpointBodyWithParam,
  IUpdateCheckpointRes,
  IDeleteCheckpointRes,
  IGetCheckpointsSettingsRes,
  IUpdateCheckpointsSettingsBody,
  ICreateCheckpointBody,
  ICreateCheckpointRes,
  IGetCheckpointDetailsRes,
} from 'types/checkpointTypes';
import { IResMessage, TQueryParams, IErrorRes } from 'types/appTypes';

const getCheckpointsAsync = createAsyncThunk<
  IGetCheckpointsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('checkpoints/getCheckpoints', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.getCheckpoints(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createCheckpointAsync = createAsyncThunk<
  ICreateCheckpointRes,
  ICreateCheckpointBody,
  { rejectValue: IErrorRes }
>('checkpoints/createCheckpoint', async (body: ICreateCheckpointBody, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.createCheckpoints(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateCheckpointAsync = createAsyncThunk<
  IUpdateCheckpointRes,
  IUpdateCheckpointBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'checkpoints/updateCheckpoint',
  async (cred: IUpdateCheckpointBodyWithParam, { rejectWithValue }) => {
    try {
      return await CheckpointsAPI.updateCheckpoints(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getCheckpointDetailsAsync = createAsyncThunk<
  IGetCheckpointDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('checkpoints/getCheckpointsDetailsAsync', async (id: number, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.getCheckpointDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteCheckpointAsync = createAsyncThunk<
  IDeleteCheckpointRes,
  number,
  { rejectValue: IErrorRes }
>('checkpoints/deleteCheckpoint', async (id: number, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.deleteCheckpoint(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getCheckpointsSettingsAsync = createAsyncThunk<
  IGetCheckpointsSettingsRes,
  void,
  { rejectValue: IErrorRes }
>('checkpoints/getCheckpointsSettingsFlags', async (_: void, { rejectWithValue }) => {
  try {
    return await CheckpointsAPI.getCheckpointsSettingsFlags();
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateCheckpointsSettingsAsync = createAsyncThunk<
  IResMessage,
  IUpdateCheckpointsSettingsBody,
  { rejectValue: IErrorRes }
>(
  'checkpoints/updateCheckpointsSettingsFlags',
  async (body: IUpdateCheckpointsSettingsBody, { rejectWithValue }) => {
    try {
      return await CheckpointsAPI.updateCheckpointsSettingFlag(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  createCheckpointAsync,
  getCheckpointsAsync,
  updateCheckpointAsync,
  deleteCheckpointAsync,
  getCheckpointsSettingsAsync,
  updateCheckpointsSettingsAsync,
  getCheckpointDetailsAsync,
};
