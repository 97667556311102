// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getIncidentsAsync, getIncidentDetailsAsync } from './incidentThank';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetIncidentsRes, IGetIncidentDetailsRes } from 'types/incidentTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceIncidentState {
  incidents: IGetIncidentsRes | null;
  incidentDetails: IGetIncidentDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceIncidentState = {
  incidents: null,
  incidentDetails: null,
  error: null,
  isLoading: false,
};

const incidentSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET INCIDENTS ASYNC THUNK
    builder.addCase(getIncidentsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getIncidentsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.incidents = payload;
    });
    builder.addCase(getIncidentsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET IDENT DETAILS ASYNC THUNK
    builder.addCase(getIncidentDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getIncidentDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.incidentDetails = payload;
    });
    builder.addCase(getIncidentDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default incidentSlice;
