// Packages
import axios from 'axios';
// Modules
import xhr from 'modules/xhr';
// Storage
import webStorage from 'modules/storage';
// API
import { ENDPOINTS } from '../api.config';
import { API_URL } from 'api/api.config';
// Interfaces and types
import { IResMessage, TQueryParams } from 'types/appTypes';
import {
  IGetCheckpointsRes,
  IUpdateCheckpointBodyWithParam,
  IUpdateCheckpointRes,
  IDeleteCheckpointRes,
  IGetCheckpointsSettingsRes,
  IUpdateCheckpointsSettingsBody,
  ICreateCheckpointBody,
  ICreateCheckpointRes,
  IGetCheckpointDetailsRes,
} from 'types/checkpointTypes';
// I18n
import { getCurrentLanguage } from 'i18n';

export const getCheckpoints = (params: TQueryParams) => {
  return xhr.get<IGetCheckpointsRes, IGetCheckpointsRes>(`${ENDPOINTS.checkpoints}`, {
    params,
  });
};

export const createCheckpoints = (body: ICreateCheckpointBody) => {
  return xhr.post<ICreateCheckpointRes, ICreateCheckpointRes>(
    `${ENDPOINTS.checkpoints}`,
    body,
  );
};

export const getCheckpointDetails = (id: number) => {
  return xhr.get<IGetCheckpointDetailsRes, IGetCheckpointDetailsRes>(
    `${ENDPOINTS.checkpoints}/${id}`,
  );
};

export const updateCheckpoints = ({ id, body }: IUpdateCheckpointBodyWithParam) => {
  return xhr.patch<IUpdateCheckpointRes, IUpdateCheckpointRes>(
    `${ENDPOINTS.checkpoints}/${id}`,
    body,
  );
};

export const deleteCheckpoint = (id: number) => {
  return xhr.delete<IDeleteCheckpointRes, IDeleteCheckpointRes>(
    `${ENDPOINTS.checkpoints}/${id}`,
  );
};

export const getCheckpointsSettingsFlags = () => {
  return xhr.get<IGetCheckpointsSettingsRes, IGetCheckpointsSettingsRes>(
    `${ENDPOINTS.checkpointsSettings}`,
  );
};

export const updateCheckpointsSettingFlag = (body: IUpdateCheckpointsSettingsBody) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.checkpointsSettings}`, body);
};

export const getCheckpointsQRCodes = (params: { jobId?: number }) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.checkpointsQRCodes}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    params,
  });
};
