// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getEmployeesAsync,
  createEmployeeAsync,
  updateEmployeeAsync,
  getEmployeeDetailsAsync,
  deleteEmployeeAsync,
  logoutUserByAdminAsync,
} from './employeeThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';
import { IEmployeeRes, IGetEmployeesRes } from 'types/employeeTypes';

type TAction<T extends TActionType> =
  | typeof getEmployeesAsync[T]
  | typeof createEmployeeAsync[T]
  | typeof updateEmployeeAsync[T]
  | typeof getEmployeeDetailsAsync[T]
  | typeof deleteEmployeeAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
  state.error = null;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  employees: IGetEmployeesRes | null;
  employeeDetails: IEmployeeRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  employees: null,
  employeeDetails: null,
  error: null,
  isLoading: false,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET EMPLOYEES ASYNC THUNK
    builder.addCase(getEmployeesAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employees = payload;
      state.error = null;
    });
    builder.addCase(getEmployeesAsync.rejected, handleErrorReducer);

    //* CREATE EMPLOYEE ASYNC THUNK
    builder.addCase(createEmployeeAsync.pending, handleLoadingReducer);
    builder.addCase(createEmployeeAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createEmployeeAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE DETAILS ASYNC THUNK
    builder.addCase(getEmployeeDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeDetails = payload;
      state.error = null;
    });
    builder.addCase(getEmployeeDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE EMPLOYEE ASYNC THUNK
    builder.addCase(updateEmployeeAsync.pending, handleLoadingReducer);
    builder.addCase(updateEmployeeAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateEmployeeAsync.rejected, handleErrorReducer);

    //* DELETE EMPLOYEE ASYNC THUNK
    builder.addCase(deleteEmployeeAsync.pending, handleLoadingReducer);
    builder.addCase(deleteEmployeeAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(deleteEmployeeAsync.rejected, handleErrorReducer);

    //* LOGOUT USER BY ADMIN  ASYNC THUNK
    builder.addCase(logoutUserByAdminAsync.pending, handleLoadingReducer);
    builder.addCase(logoutUserByAdminAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(logoutUserByAdminAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default employeesSlice;

export const { clearErrors } = employeesSlice.actions;
