// Core
import { useEffect } from 'react';
// Packages
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { LoadScript } from '@react-google-maps/api';
import { BrowserRouter as Router } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'moment/locale/vi';
import 'moment/locale/pt';
import 'moment/locale/es';
import SentryInstance from 'sentryConfig';
// Components
import { Layout } from 'components/lib';
import { Content, Header, Loader } from 'components';
// Custom Hooks
import { useRoutePermissions } from 'customHooks';
// Redux
import { getCurrentUserAsync } from 'redux/user/userThunk';
import { useAppDispatch, useAppSelector, STORE_KEYS } from 'redux/store';
import { getCheckpointsSettingsAsync } from 'redux/checkpoints/checkpointsThunk';
// Modules
import storage from 'modules/storage';
// Routes
import { AppRouter } from 'router';
import routes, { ROUTES } from 'router/routes';
// Helpers
import { ROLES } from 'helpers/data/constants';
// Configs
import dateConfig from 'configs/dateConfig';
// Interfaces and types
import { TGoogleLibraries } from 'types/appTypes';
// I18n
import {
  TLang,
  getCurrentLanguage,
  getCurrentLocale,
  getLocaleOptions,
  languages,
  changeLanguage,
  TLangKeys,
} from 'i18n';

const libraries: TGoogleLibraries = ['places', 'geometry'];
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY as string;

const App = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(STORE_KEYS.USER);
  const { isLoggedIn, userId } = useAppSelector(STORE_KEYS.AUTH);
  const isAuth = !!isLoggedIn && !!userId;
  const allowedRoutes = useRoutePermissions(routes);

  useEffect(() => {
    isAuth && dispatch(getCurrentUserAsync());
  }, [dispatch, isAuth]);

  // need for hiding/opening this page depends on flag in checkpoints page

  useEffect(() => {
    if (user && allowedRoutes?.some(route => route.path === ROUTES.CHECKPOINTS))
      dispatch(getCheckpointsSettingsAsync());

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    let language = storage.getData<TLang>('timeGoLang')?.lng;
    const browserLanguage = navigator.language.split('-')[0];

    if (language && language in languages) return;

    let appLanguage = 'en';

    if (browserLanguage in languages) {
      appLanguage = browserLanguage;
    }

    return changeLanguage(appLanguage as TLangKeys);
  }, []);

  useEffect(() => {
    if (user) {
      const currentLanguage = getCurrentLanguage();
      const localeOptions = getLocaleOptions(user?.payroll?.startDay);

      dateConfig.configureTimeFormat(user?.timeFormat);

      const options = user.role === ROLES.business_company ? {} : localeOptions;
      moment.defineLocale(currentLanguage, options);
    }
  }, [user]);

  const currentLocale = getCurrentLocale();

  return (
    <ConfigProvider locale={currentLocale}>
      <Router>
        <Layout style={{ height: '100%' }}>
          {isAuth && <Header />}
          <LoadScript
            id="time-go-map"
            language={currentLocale.locale}
            libraries={libraries}
            loadingElement={<Loader isLoading />}
            googleMapsApiKey={googleMapsApiKey}
          >
            <DndProvider backend={HTML5Backend}>
              <Content isAuth={isAuth} user={user}>
                <AppRouter />
              </Content>
            </DndProvider>
          </LoadScript>
        </Layout>
      </Router>
    </ConfigProvider>
  );
};

export default SentryInstance.withProfiler(App);
