// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { IncidentTypesAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetIncidentTypesRes,
  ICreateIncidentTypeBody,
  IUpdateIncidentTypeBodyWithParam,
  ICreateIncidentTypeRes,
  IIncidentTypeDetailsRes,
  ICopyIncidentTypeBody,
} from 'types/incidentTypesTypes';
import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';

const getIncidentTypesAsync = createAsyncThunk<
  IGetIncidentTypesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('incidentTypes/getIncidentTypes', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await IncidentTypesAPI.getIncidentsTypes(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createIncidentTypesAsync = createAsyncThunk<
  ICreateIncidentTypeRes,
  ICreateIncidentTypeBody,
  { rejectValue: IErrorRes }
>(
  'incidentTypes/createIncidentType',
  async (params: ICreateIncidentTypeBody, { rejectWithValue }) => {
    try {
      return await IncidentTypesAPI.createIncidentType(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getIncidentTypeDetailsAsync = createAsyncThunk<
  IIncidentTypeDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('incidentTypes/getIncidentTypeDetails', async (params: number, { rejectWithValue }) => {
  try {
    return await IncidentTypesAPI.getIncidentTypeDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateIncidentTypeAsync = createAsyncThunk<
  IResMessage,
  IUpdateIncidentTypeBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'incidentTypes/updateIncidentType',
  async ({ id, body }: IUpdateIncidentTypeBodyWithParam, { rejectWithValue }) => {
    try {
      return await IncidentTypesAPI.updateIncidentType({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteIncidentTypeAsync = createAsyncThunk<
  IResMessage,
  number,
  { rejectValue: IErrorRes }
>('incidentTypes/deleteIncidentType', async (id: number, { rejectWithValue }) => {
  try {
    return await IncidentTypesAPI.deleteIncidentType(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const copyIncidentTypeAsync = createAsyncThunk<
  IResMessage,
  ICopyIncidentTypeBody,
  { rejectValue: IErrorRes }
>(
  'incidentTypes/copyIncidentType',
  async (body: ICopyIncidentTypeBody, { rejectWithValue }) => {
    try {
      return await IncidentTypesAPI.copyIncidentType(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getIncidentTypesAsync,
  createIncidentTypesAsync,
  getIncidentTypeDetailsAsync,
  updateIncidentTypeAsync,
  deleteIncidentTypeAsync,
  copyIncidentTypeAsync,
};
