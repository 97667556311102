// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetUserRes,
  IVerifyEmailRes,
  IResendEmailRes,
  IUpdateUserBody,
  IUpdateUserRes,
  IGetInterfaceSettings,
  IUpdateInterfaceSettings,
  INavHistory,
} from 'types/userTypes';
import { IResMessage } from 'types/appTypes';

export const getCurrentUser = () => {
  return xhr.get<IGetUserRes, IGetUserRes>(
    `${ENDPOINTS.currentUser}?include[]=platformPermissions`,
  );
};

export const updateCurrentUser = (body: IUpdateUserBody) => {
  return xhr.patch<IUpdateUserRes, IUpdateUserRes>(`${ENDPOINTS.currentUser}`, body);
};

export const verifyEmail = (token: string) => {
  return xhr.post<IVerifyEmailRes, IVerifyEmailRes>(`${ENDPOINTS.verifyEmail}`, {
    token,
  });
};

export const resendVerificationEmail = () => {
  return xhr.post<IResendEmailRes, IResendEmailRes>(`${ENDPOINTS.resendVerificationEmail}`);
};

export const getInterfaceSettings = () => {
  return xhr.get<IGetInterfaceSettings, IGetInterfaceSettings>(
    `${ENDPOINTS.interfaceSettings}`,
  );
};

export const updateInterfaceSettings = (body: IUpdateInterfaceSettings) => {
  return xhr.patch<IResMessage, IResMessage>(`${ENDPOINTS.interfaceSettings}`, body);
};

export const getLabelsOfMostVisitedPages = () => {
  return xhr.get<INavHistory[], INavHistory[]>(`${ENDPOINTS.mostVisitedPages}`);
};

export const saveVisitedPageLabel = (body: INavHistory) => {
  return xhr.post<IResMessage, IResMessage>(`${ENDPOINTS.mostVisitedPages}`, body);
};
