// Core
import { ReactNode } from 'react';
// Packages
import { Location } from 'history';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
// Components
import {
  Avatar,
  GridCol,
  GridRow,
  MainHeader,
  Menu,
  Space,
  Title,
  Button,
} from 'components/lib';
import { Table } from 'components';
// Custom Hooks
import { useCompanyPath, useRoutePermissions, useBreakpoint } from 'customHooks';
import useGetLabels from './useGetLabels';
// Redux
import { useAppSelector, STORE_KEYS } from 'redux/store';
// Routes
import { TSubRoute } from 'router/routes';
import { ROUTES } from 'router/routes';
// Helpers
import { setQueryString } from 'helpers/queryString';
// I18n
import { t } from 'i18n';
// Styles
import styles from './pageHeader.module.scss';

interface IProps {
  title?: string;
  titleBlock?: ReactNode;
  icon?: ReactNode;
  hasMenu?: boolean;
  hasGoBack?: boolean;
  goBackUrl?: string;
  subRoutes?: TSubRoute[];
  children?: ReactNode;
  fromLocation?: { from: Location };
}

const PageHeader = ({
  icon,
  title = '',
  titleBlock,
  hasMenu = false,
  hasGoBack = false,
  goBackUrl,
  subRoutes,
  children,
  fromLocation,
}: IProps) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { lg } = useBreakpoint();

  const { getUpdatedPath } = useCompanyPath();
  const allowedRoutes = useRoutePermissions(subRoutes ?? []);

  const { user } = useAppSelector(STORE_KEYS.USER);

  const { settings } = useAppSelector(STORE_KEYS.CHECKPOINTS);

  const { current: page } = Table.paginationSettings;
  let defaultQueryString = setQueryString({ page, limit: user?.paginationPreference });
  let defaultQueryStringWithActiveFilter = setQueryString({
    page,
    limit: user?.paginationPreference,
    status: 'active',
  });

  const handleGoBack = () => {
    if (!goBackUrl) {
      return;
    }

    const updatedPath = getUpdatedPath(goBackUrl, params);

    const queryString =
      fromLocation && fromLocation?.from?.search
        ? fromLocation.from.search
        : `?${defaultQueryString}`;

    history.push(`${updatedPath}${queryString}`);
  };

  const navMenuItemClass = lg ? styles.navMenuItemDesktop : styles.navMenuItem;
  const { labelsOfPagesThatShouldHaveActiveStatusFilter } = useGetLabels();

  return (
    <MainHeader className={styles.pageHeader}>
      <GridRow align="middle" justify="start" className={styles.menuContainer}>
        <GridCol span={24} className={styles.menuWrapper}>
          <div>
            <Space size="middle" align="center">
              {hasGoBack && <Button icon={<AiOutlineLeft />} onClick={handleGoBack} />}
              {icon && <Avatar src={icon} shape="square" />}
              {title && (
                <Title className={styles.title} ellipsis={{ tooltip: title }} level={4}>
                  {title}
                </Title>
              )}
            </Space>
          </div>
          <div className={styles.titleBlockContainer}> {titleBlock}</div>
        </GridCol>

        {hasMenu && allowedRoutes && (
          <GridCol flex="auto">
            <GridRow align="middle" justify="center">
              <GridCol>
                <Menu
                  theme="light"
                  mode="horizontal"
                  selectedKeys={[location.pathname]}
                  className={styles.navMenu}
                >
                  {allowedRoutes.map(({ path, label, type }) => {
                    const isActiveFilterShouldBeAdded =
                      labelsOfPagesThatShouldHaveActiveStatusFilter.includes(label);

                    const defaultQueryStringOfPage = isActiveFilterShouldBeAdded
                      ? defaultQueryStringWithActiveFilter
                      : defaultQueryString;

                    if (
                      path === ROUTES.CHECKPOINTS_PARKING_LIST &&
                      !settings?.isParkingIssuesEnabled
                    ) {
                      return null;
                    }

                    if (
                      path === ROUTES.CHECKPOINTS_INCIDENTS_LIST &&
                      !settings?.isIncidentsEnabled
                    ) {
                      return null;
                    }

                    return (
                      <Menu.Item
                        key={getUpdatedPath(path, params)}
                        className={navMenuItemClass}
                      >
                        <Link
                          to={{
                            pathname: getUpdatedPath(path, params),
                            search: type === 'list' ? `?${defaultQueryStringOfPage}` : '',
                            state: fromLocation?.from?.pathname
                              ? {
                                  from: {
                                    pathname: fromLocation?.from?.pathname,
                                    search: fromLocation?.from?.search || '',
                                  },
                                }
                              : '',
                          }}
                        >
                          {t(`subNav.${label}`)}
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </GridCol>
            </GridRow>
          </GridCol>
        )}
      </GridRow>

      {children && (
        <GridRow>
          <GridCol span={24}>{children}</GridCol>
        </GridRow>
      )}
    </MainHeader>
  );
};

export default PageHeader;
